import { apiClient, ApiClientConfig } from "api";
import { axiosInstance } from "api/axios-instance";
import { CONFIG } from "config";
import { AnyARecord } from "dns";
import { EventsParams, EventsResponse, UpdateShowHideDTO } from "features/events";
import { ItemSales } from "types/items-sales";


export type ItemSalesResponse = PaginatedResponse<ItemSales>;

type ParticipateQueryParams = {
  participant_id?: string;
  invoice_id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  event_name?: string;
  order_by?: string;
  size?: number;
  page?: number;
};

export interface ParticipateResponce {
  participant_id: string;
  invoice_id: string;
  first_name: string;
  last_name: string;
  email: string;
  event_name: string;
}

export interface PaginatedResponse<T> {
  data: any;
  page: number;
  size: number;
  pages: number;
  total: number;
  items: T[];
}

type Participant = {
  participant_id: number;
  label_name: string;
  label_value: string;
};

type ParticipantList = {
  participant_id: number;
  first_name: string;
  last_name: string;
  price: number;
  category_name: string;
  category_name_arabic: string;
  booking_type: string;
  email_address: string;
  event_name: string;


  participant_list: Participant[];
  tshirt_size: string;
  refund_protect_price: number;
  image_url: string;
  age: number;
  currency_name: string;
};

type EventDetail = {
  event_id: number;
  event_date: string | null;
  event_name: string;
  event_name_arabic: string;
  receipt_image_url: string;
  category_name: string;
  image_url: string;
};

export type InvoiceDetailsResponse = {
  id: string;
  invoice_no: string;
  total_price: number;
  order_date: string;
  phone_number: string | null;
  email_address: string;
  booking_status: string;
  payment_status: string;
  full_name: string;
  payment_method: string;
  event_detail: EventDetail;
  delivery_address: any;
  all_participant: ParticipantList[];
  item_list: any[];
  refund_booking_amount: any;
  total_addons: any;
  total_discount: any;
  total_item_price: any;
  total_participant_price: any;
  delivery_price: any;
  refundable_amount: any;
  sub_total: any
};


export type WalletsDTO = {
  id?: string;
  email: string;
  name: string;
  description: string;
  amount: number;
  customer_id: string;
  first_name: string;
  last_name: string;
  mobile_no: string;
}

export type paymentLinkDTO = {
  booking_id: string;
  source: string
}

export type confirmPayDTO = {
  bulkid: number;
}

export type ParticipateListResponse = PaginatedResponse<ParticipateResponce>;

export const TransectionApiServices = () => {

  const getParticipateList = async (
    params: ParticipateQueryParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<ParticipateListResponse>({
      ...callConfig,
      endpoint: `/admin/participant/participant`,
      params,
    });

    return response.data;
  };

  const updateParticipant = async (payload: UpdateShowHideDTO) => {
    const response = await apiClient({
      endpoint: `/participant/updateformsvalue`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getParticiPantdetails = async (
    id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<any>({
      ...callConfig,
      endpoint: `/admin/participant/participantdetails?${id}`,
    });

    return response.data;
  };


  const getInvoiceList = async (
    flag: string,
    params: ParticipateQueryParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<ParticipateListResponse>({
      ...callConfig,
      endpoint: `/admin/participant/invoicelist?Flag=${flag}`,
      params,
    });

    return response.data;
  };

  // const exportInvoiceList = async (
  //   params: ParticipateQueryParams,
  //   callConfig?: Partial<ApiClientConfig>,
  // ) => {
  //   const response = await apiClient<ParticipateListResponse>({
  //     ...callConfig,
  //     endpoint: `/admin/participant/downloadinvoicelist`,
  //     params,
  //   });

  //   return response.data;
  // };

  const replaceSubstring = (
    input: string,
    target: string,
    replacement: string,
  ): string => {
    const startIndex = input.indexOf(target);
    const endIndex = startIndex + target.length;
    const replacedStr =
      input.slice(0, startIndex) + replacement + input.slice(endIndex);

    return replacedStr;
  };

  const exportInvoiceList = async () => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );
    const response = await axiosInstance.get(
      `${sportsService}/v1/admin/participant/downloadinvoicelist`,
      {
        responseType: 'blob',
      },
    );

    return response;
  };

  const getInvoiceDetails = async (
    id: string,
  ) => {
    const response = await apiClient<InvoiceDetailsResponse>({
      endpoint: `/events/getorderdetails/${id}`,
    });

    return response.data;
  };

  // const exportPrticipants = async (params: { [key: string]: any }) => {
  //   const sportsService = replaceSubstring(
  //     CONFIG.SUFFIX_API_BASE_URL,
  //     '/identity/',
  //     '/events/',
  //   );
  //   const filteredParams = Object.fromEntries(
  //     Object.entries(params).filter(([_, value]) => value != null && value !== "")
  //   );
  //   const queryParams = new URLSearchParams(filteredParams);
  //   const response = await axiosInstance.get(
  //     `${sportsService}/v1/admin/participant/downloadparticipantlist?${queryParams.toString()}`,

  //     {
  //       responseType: 'blob',
  //     },
  //   );

  //   return response;
  // };


  const getDropDownList = async (Flag: any) => {
    const response = await apiClient({
      endpoint: `/admin/dropdownlist?${Flag}`
    })

    return response.data
  }

  const getBulkUploadEvent = async () => {
    const response = await apiClient({
      endpoint: `/events`
    })

    return response.data
  }

  const getSampleExcel = async (id: any) => {
    const response = await apiClient({
      endpoint: `/admin/events/getexcelsample?event_id=${id}`
    })

    return response.data
  }
  // const getSampleExcel = async (id: any) => {
  //   // Replace baseUrl for Sports API if the request is for Sports APIs..
  //   const sportsService = replaceSubstring(
  //     CONFIG.SUFFIX_API_BASE_URL,
  //     '/identity/',
  //     '/events/',
  //   );
  //   const response = await axiosInstance.get(
  //     `${sportsService}/v1/reports/downloadexcelfile/${id}`,
  //     {
  //       responseType: 'blob',
  //     },
  //   );

  //   return response;
  // };

  const uploadBulkExcel = async (formData: FormData) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );

    const response = await axiosInstance.post(
      `${sportsService}/v1/bulkupload/importexcel`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    return response.data;
  };


  const getWalletList = async (
    params: ParticipateQueryParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<ParticipateListResponse>({
      ...callConfig,
      endpoint: `/admin/wallets/userwallets`,
      params,
    });

    return response.data;
  };

  const getUsers = async (email: string) => {
    const response = await apiClient({
      endpoint: `/admin/users?roles=User&page=1&size=10&email=${email}`
    })

    return response.data
  }

  const createWallet = async (payload: WalletsDTO) => {
    const response = await apiClient({
      endpoint: `admin/wallets/create`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const sendInvoice = async (id: any, payload: any) => {
    const response = await apiClient({
      endpoint: `transactions/receiptnotification?order_no=${id}`,
      method: 'GET',
      data: payload,
    });

    return response.data;
  };

  const getBulkUploadList = async (
    params: ParticipateQueryParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<ParticipateListResponse>({
      ...callConfig,
      endpoint: `/bulkupload/getbulkuplodlist`,
      params,
    });

    return response.data;
  };


  const getUploadDetails = async (
    params: any,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/bulkupload/getbulkuplaoddetais/${params.id}/${params.page}/${params.size}`,

    });

    return response.data;
  };

  const createBooking = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/bookings`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const confirmPaymentBulk = async (payload: confirmPayDTO) => {
    const response = await apiClient({
      endpoint: `/bulkupload/confirmpayment`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getWaitingList = async (
    params: ParticipateQueryParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<ParticipateListResponse>({
      ...callConfig,
      endpoint: `/admin/participant/waitingparticipantinvoicelist`,
      params,
    });

    return response.data;
  };

  const createPaymentLink = async (payload: paymentLinkDTO) => {
    const response = await apiClient({
      endpoint: `/bookings/createlinkforwaitingparticipant`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const rejectPayment = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/participant/rejectwaitinglist`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getReportsList = async (
    params: ParticipateQueryParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<ParticipateListResponse>({
      ...callConfig,
      endpoint: `/admin/participant/getexportreports`,
      params,
    });

    return response.data;
  };


  const exportFinancialRequest = async (params: { [key: string]: any }) => {
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => value != null && value !== "")
    );
    const queryParams = new URLSearchParams(filteredParams);
    const response = await axiosInstance.get(
      `${sportsService}/v1/admin/participant/TakeReportResuest?${queryParams.toString()}`,
    );

    return response.data;
  };


  const applyCouponBulk = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/events/ApplyEventDiscount`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const removeCouponBulk = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/events/updatestatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getItemSalesList = async (
    params: ParticipateQueryParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<ItemSalesResponse>({
      ...callConfig,
      endpoint: `/admin/itemsales/invoicelist`,
      params,
    });

    return response.data;
  };


  const getItemSalesDetails = async (
    params: EventsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<EventsResponse>({
      ...callConfig,
      endpoint: `/admin/itemsales/orderdetails`,
      params,
    });

    return response.data;
  };

  const updateItemsStatus = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/admin/itemsales/updateitemorderstatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const acceptWaitingPayment = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/bookings/AcceptPaymentForWaitingParticipant`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const invoiceListRefresh = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/bookings/checkpaymentstatus`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };


  const getExpoBoothList = async (
    params: ParticipateQueryParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<ParticipateListResponse>({
      ...callConfig,
      endpoint: `/admin/expobooth/getregisteredbooths`,
      params,
    });

    return response.data;
  };


  const updateExpoBoothNo = async (payload: AnyARecord) => {
    const response = await apiClient({
      endpoint: `admin/expobooth/bootheditdelete`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const generatePaymentLinkBoot = async (payload: any) => {
    const response = await apiClient({
      endpoint: `admin/expobooth/generateboothpaymentlink`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  // const getExpoBoothParticiPantDetails = async (
  //   id: string,
  //   callConfig?: Partial<ApiClientConfig>,
  // ) => {
  //   const response = await apiClient<any>({
  //     ...callConfig,
  //     endpoint: `/admin/ExpoBooth/GetBoothParticipantDetails?${id}`,
  //   });

  //   return response.data;
  // };

  const getExpoBoothParticiPantDetails = async (id: any) => {
    // Replace baseUrl for Sports API if the request is for Sports APIs..
    const sportsService = replaceSubstring(
      CONFIG.SUFFIX_API_BASE_URL,
      '/identity/',
      '/events/',
    );
    const response = await axiosInstance.get(
      `${sportsService}/v2/admin/expobooth/getboothparticipantdetails/${id}`
    );

    return response;
  };

  // const getSendEmail = async (
  //   params: ParticipateQueryParams,
  //   callConfig?: Partial<ApiClientConfig>,
  // ) => {
  //   const response = await apiClient<ParticipateListResponse>({
  //     ...callConfig,
  //     endpoint: `/sendparticipantemail/spe`,
  //     params,
  //   });

  //   return response.data;
  // };

  const getSendEmail = async (payload: any) => {
    const response = await apiClient({
      endpoint: `/sendparticipantemail/spe?participant_id=${payload.participant_id}`,
      // method: 'GET',
    });

    return response.data;
  };

  return {
    getParticipateList,
    getParticiPantdetails,
    updateParticipant,
    getInvoiceList,
    exportInvoiceList,
    getInvoiceDetails,
    // exportPrticipants,
    getDropDownList,
    getSampleExcel,
    uploadBulkExcel,
    getWalletList,
    getUsers,
    createWallet,
    sendInvoice,
    getBulkUploadList,
    getUploadDetails,
    getBulkUploadEvent,
    createBooking,
    confirmPaymentBulk,
    getWaitingList,
    createPaymentLink,
    rejectPayment,
    getReportsList,
    exportFinancialRequest,
    applyCouponBulk,
    removeCouponBulk,
    getItemSalesList,
    getItemSalesDetails,
    updateItemsStatus,
    acceptWaitingPayment,
    invoiceListRefresh,
    getExpoBoothList,
    updateExpoBoothNo,
    generatePaymentLinkBoot,
    getExpoBoothParticiPantDetails,
    getSendEmail
  }
}






