import { Button, DatePicker, Form, Input, message, Select } from 'antd';
import { TransectionApiServices } from 'features/transaction_list/api/transection-api.service';
import { useUserContext } from 'features/users';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { payFormatDate } from 'utils/payload-date-';

type props = {
    closeModal: () => void;
    isOpen: boolean;
    modalType: string
};

interface Ticket {
    Text: string;
    Id: any;
}

interface ApiResponse {
    items: Ticket[];
}


export const ParticipantReportModal = ({
    closeModal,
    isOpen,
    modalType
}: props) => {
    const { user } = useUserContext();
    const userHasAccessToEventList:any = user?.user_id != 107093;

    const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const transaction = [
        { value: "all", label: "All" },
        { value: "Captured", label: "Captured" },
        { value: "InCompleted", label: "InCompleted" }
    ]
    const handleClose = () => {
        form.resetFields();
        closeModal();
    };
    const onFinish = (formValues: any) => {
        handleExport(formValues);
    };

    const handleExport = async (values: {
        event_name?: any;
        category_names?: any;
        transaction_status?: any;
        file_name?: any;
        Date_From?: any;
        Date_To?: any;
    }) => {
        setLoading(true);
        // if (modalType === "participants") {
        //     try {
        //         const params = {
        //             event_id: values.event_name,
        //             cat_id: values.category_names,
        //             transaction_status: values.transaction_status,
        //             file_name: values.file_name,
        //             start_date: values.Date_From && payFormatDate(values.Date_From),
        //             end_date: values.Date_To && payFormatDate(values.Date_To),
        //         };

        //         const response = await TransectionApiServices().exportPrticipants(params);

        //         // Check if the response data is a Blob (file)
        //         if (response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        //             const url = window.URL.createObjectURL(new Blob([response.data]));
        //             const link = document.createElement('a');
        //             link.href = url;
        //             link.setAttribute('download', `${values.file_name}.xlsx`);
        //             document.body.appendChild(link);
        //             link.click();
        //             document.body.removeChild(link);
        //             message.success("Excel file successfully downloaded");
        //             setLoading(false);
        //             // setIsSuccess(true);
        //         } else {
        //             message.error("File doesn't contain valid data");
        //         }
        //     } catch (error) {
        //         message.error("File doesn't contain valid data");
        //         setLoading(false);
        //     }
        //     finally {
        //         setLoading(false);
        //     }
        // } else {

        try {
            const params = {
                event_id: values.event_name,
                cat_id: values.category_names,
                transaction_status: values.transaction_status,
                FileName: values.file_name,
                start_date: values.Date_From && payFormatDate(values.Date_From),
                end_date: values.Date_To && payFormatDate(values.Date_To),
                type: modalType,
            };

            const response = await TransectionApiServices().exportFinancialRequest(params);

            if (response.ResponseStatus === 1) {
                setLoading(false);
                setIsSuccess(true);

            } else {
                message.error(response.ResponseMessage);
            }
        } catch (error) {
            message.error("File doesn't contain valid data");
            setLoading(false);
        }
        finally {
            setLoading(false);
        }

    };


    const [event, setEvent] = useState({
        items: []
    })
    const [tickets, setTickets] = useState(
        [{ label: 'All', value: '' }]
    )

    const getEvent = async () => {
        try {
            const apires = await TransectionApiServices().getDropDownList(`Flag=${'Events'}`)
            // const filteredEvents = (apires as any)?.items.filter((event: { Id: number; }) => event.Id === 732);

            //  setEvent(filteredEvents as any);
            setEvent(apires as any)
        } catch (error) {
            message.error('')
        }
    }

    const getTickets = async (value: string) => {
        try {
            const response = await TransectionApiServices().getDropDownList(`Flag=${'EventCategory'}&CascadeId=${value}`)
            const apires = response as ApiResponse;
            setTickets([
                { label: 'All', value: '' },
                ...apires.items.map((res) => ({ label: res.Text, value: res.Id }))
            ]);
        } catch (error) {
            message.error('')
        }
    }

    useEffect(() => {
        if (isOpen) {
            getEvent();
            form.setFieldValue("transaction_status", "all");
            setIsSuccess(false);
        }

    }, [isOpen, form])

    const formatTitle = (title: string) => {
        if (!title) return '';

        // Capitalize the first character
        const capitalized = title.charAt(0).toUpperCase() + title.slice(1);

        // Insert a space before "Report"
        const formatted = capitalized.replace(/([a-z])([A-Z])/g, '$1 $2');

        return formatted;
    };



    return (
        <div className='popup'>
            <div className='modal_head'>
                <h4>{formatTitle(modalType)} Export</h4>
                <button onClick={handleClose} className='close-btn'>
                    &times;
                </button>

            </div>
            <div className='modal_body'>
                <Form
                    // id={formId}
                    // name={formId}
                    className='filters-form'
                    form={form}
                    layout='vertical'
                    onFinish={onFinish}
                    // initialValues={initialValues}
                    onFinishFailed={() => {
                        setValidateTrigger(['onChange']);
                    }}
                    validateTrigger={validateTrigger}
                >
                    {/* <ContentFilters.FormItemsWrapper> */}
                    <FormSectionsSeparator title={""}>

                        {modalType === "ParticipantReport" ? <>
                            <Form.Item
                                // label={t('shops.formfield.fieldName.label')}
                                label={'Event Name'}
                                name={"event_name"}
                                rules={[
                                    {
                                        required: true,
                                        message: t('validateMessages.custom.nomsg'),
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    options={event?.items?.filter((res: any) => (res.Id === 732 && !userHasAccessToEventList) || (res.Id === res.Id && userHasAccessToEventList)).map((res: any) => ({ label: res.Text, value: res.Id }))}
                                    placeholder={"Select Event Name"}
                                    onChange={(value: string) => getTickets(value)}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                // label={t('shops.formfield.fieldName.label')}
                                label={'Category Names'}
                                name={"category_names"}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: t('validateMessages.custom.nomsg'),
                            //     },
                            // ]}
                            >
                                <Select
                                    showSearch
                                    options={tickets}
                                    placeholder={"Select Event Name"}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }

                                />
                            </Form.Item>
                        </>
                            :
                            <>
                                <Form.Item
                                    label={"Date From"}
                                    name={"Date_From"}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validateMessages.custom.nomsg'),
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item
                                    label={"Date To"}
                                    name={"Date_To"}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validateMessages.custom.nomsg'),
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} />
                                </Form.Item>
                            </>}



                        <Form.Item
                            // label={t('shops.formfield.fieldName.label')}
                            label={'Transaction Status'}
                            name={"transaction_status"}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),

                                },
                            ]}
                        >

                            <Select
                                placeholder='Select Transaction Status'
                                options={transaction}
                            >

                            </Select>
                        </Form.Item>
                        <Form.Item
                            // label={t('shops.formfield.fieldName.label')}
                            label={'File Name'}
                            name={"file_name"}
                            rules={[
                                {
                                    required: true,
                                    message: t('validateMessages.custom.nomsg'),
                                },
                            ]}
                        >
                            <Input placeholder={'Enter File Name'} />
                        </Form.Item>

                    </FormSectionsSeparator>

                    {!isSuccess &&
                        <Button type='primary' htmlType='submit' loading={loading}>
                            Generate Report
                        </Button>

                    }

                </Form>
                {isSuccess && <>
                    {/* <h1>Download Report</h1> */}
                    <div className='gnrte_rprt'>
                        <h2>
                            Preparing Your Report<span className="loading">...</span>
                        </h2>

                        <h3>To Check your report status and access the file, Please <Link to={ROUTES.TRANSACTION__REPORTS}>Click here</Link></h3>
                    </div>
                </>}
            </div>
        </div>
    );
};



