import { usePaginationParams } from 'hooks';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { UploadListTopBar } from '../component/top-bar/upload-list-bar';
import { UploadList } from '../component/transaction-table-list/upload-list';
import { useGetBulkUploadList } from '../use-cases/get-bulk-upload-list';

export const UploadListView = () => {
  const location = useLocation();

  const updatedFlag: string | undefined = location.pathname.includes("/group-registration-List")
    ? "GroupRegistration"
    : undefined

  const updatedTitle: string | undefined = location.pathname.includes("/group-registration-List")
    ? "Group Registration List"
    : "Bulk Upload List"

  const { params, updateParams } = usePaginationParams<any>({
    Flag: "admin",
    Origin: updatedFlag,
    page: 1,
    size: 10,
  });

  const { data, refetch, isFetching } = useGetBulkUploadList(params);

  useEffect(() => {
    updateParams({
      ...params, Origin: updatedFlag
    });
    refetch();
  }, [updatedFlag, refetch]); // Dependency on updatedFlag and refetch

  return (
    <>
      <UploadListTopBar updatedTitle={updatedTitle} />

      <UploadList
        isSocialListLoading={isFetching}
        DeliveryList={data}
        tableParams={params as any}
        updateParams={updateParams}
        refetch={refetch}
        updatedFlag={updatedFlag}

      />
    </>
  );
};
