import { MoreOutlined, RightOutlined } from '@ant-design/icons';
import { Dropdown, Empty, MenuProps, Skeleton, Space, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { useUpdateStatus } from 'features/transaction_list/use-cases/updateStatus';
import { usePermissions } from 'features/users';
import { EndUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { User } from 'types';

type ParticipateTableProps = {
  isSocialListLoading: boolean;
  DeliveryList: any;
  refetch: any,
  tableParams: EndUserFilterFormValuesPaginated;
  updateParams: Dispatch<SetStateAction<EndUserFilterFormValuesPaginated>>;
  updatedFlag: any
};
export const UploadList = ({
  DeliveryList,
  isSocialListLoading,
  tableParams,
  updateParams,
  updatedFlag,
}: ParticipateTableProps) => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  const { mutate } = useUpdateStatus();


  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<User> | SorterResult<User>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'

          : '-name',
    });
  };


  function formatString(str: string) {
    if (str) {
      return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  }



  const handleRegistrationLink = (id: number, flag: boolean) => {

    const payload = {
      id: id,
      flag: flag ? "OPEN_REGISTRATION_LINK" : "CLOSED_REGISTRATION_LINK"
    }

    mutate(payload)
  }

  const items = (record: any): MenuProps['items'] => {
    const isClosed = record?.is_closed_link;

    const menuItems = [
      {
        label: isClosed
          ? "Open Registration Link"
          : "Close Registration Link",
        key: '0',
        onClick: () => handleRegistrationLink(record?.id, record?.is_closed_link),
      },
    ];

    return menuItems;
  };

  if (!permissions) return null;

  return (
    <>
      <StyledTableHeader>
        <div style={{ marginTop: '32px', color: 'black' }}>
          <RecordCountBadge
            text={updatedFlag === "GroupRegistration" ? "Group Registration found" : "Bulk Upload found"}
            count={DeliveryList?.total || 0}
          />
        </div>
      </StyledTableHeader>
      <Table
        className='cstltblwthldr'
        dataSource={isSocialListLoading ? [] : DeliveryList?.items}
        // loading={isSocialListLoading}
        locale={{
          emptyText: isSocialListLoading ? <Skeleton active={true} /> : <Empty />
        }}
        loading={isSocialListLoading && { indicator: <div><TableLoader /></div> }}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams?.page,
          defaultPageSize: tableParams?.size,
          total: DeliveryList?.total,
        }}

      // scroll={{ x: 1440 }}
      >
        <Column
          title={'Event'}
          className='pointer'
          // dataIndex={'event_name'}
          onCell={(record: any) => {
            return {
              onClick: () => {
                updatedFlag ? navigate(getRoute(ROUTES.TRANSACTION__GROUP_REGISTRATION_LIST_DETAILS, record.id))
                  :
                  navigate(getRoute(ROUTES.TRANSACTION__BULK_UPLOAD_LIST_DETAILS, record.id));

              },
            };
          }}
          render={(value: any, record: any) => {
            return (
              <span className='arow_tab'>
                <RightOutlined />
                <h1>{record.event_name}</h1>
              </span>

            )
          }}

        />
        <Column
          title={'Group'}
          dataIndex={'group_name'}
        // sorter={true}
        // sortDirections={['ascend', 'descend', 'ascend']}
        // defaultSortOrder={'ascend'}
        />
        <Column
          title={'Contact Person'}
          dataIndex={'contact_person_name'}
        // sorter={true}
        // sortDirections={['ascend', 'descend', 'ascend']}
        // defaultSortOrder={'ascend'}
        />
        <Column
          title={'Mobile'}
          dataIndex={'contact_person_phone'}

        />

        <Column
          title={'Email'}
          dataIndex={'contact_person_email'}

        />

        <Column
          title={'No. of Participants'}
          dataIndex={'number_of_participant'}

        />
        <Column
          title={'Payment Method'}
          // dataIndex={'payment_method'}
          render={(value: any, record: any) => {
            return (
              <h1>{formatString(record?.payment_method)}</h1>
            )
          }}

        />

        <Column
          title={'Status'}
          dataIndex={'payment_Status'}

        />
        {updatedFlag === "GroupRegistration" &&
          <Column
            title={'Registration Status'}
            // dataIndex={'payment_method'}
            render={(record: any) => {
              return (
                <h1 style={{ color: record?.is_closed_link ? "red" : "green" }}>{record?.is_closed_link ? "Closed" : "Open"}</h1>
              )
            }}

          />}

        {updatedFlag === "GroupRegistration" &&
          <Column
            title={'Action'}
            render={(_, record: any) => (
              <>
                {record?.payment_Status != "SUCCESS" &&
                  <Dropdown menu={{ items: items(record) }} trigger={['click']} className='pointer'>
                    <Space >
                      <MoreOutlined />
                    </Space>
                  </Dropdown>}
              </>

            )}

          />}

      </Table>
    </>
  );
};
