import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { EventsApiService, eventsQueryKeys } from 'features/events';
import { queryClient } from 'providers';


const { updateExpoBoothParticipant } = EventsApiService();

export const useUpdateExpoBoothParticipant = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: any) => {
      return updateExpoBoothParticipant(payload as any);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: eventsQueryKeys.all,
        });
        message.success("Participate Details has been updated successfully!!");
      },
    },
  );
};
