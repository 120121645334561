import { Button, ContentFilters, ContentTopBar } from 'components';
import {
  EventsTable,
  useGetEvents,
} from 'features/events';
import { usePermissions, useUserContext } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { MainEventFilterForm } from '../components/main-event-filter-form/main-event-filter-form';
import { MainEventsFilterFormPaginated, MainEventsFilterFormValues } from '../components/main-event-filter-form/types';
import { AddEventModal } from '../components/modals/add-event-modal';
import { useGetNewDropdown } from '../use-cases/get-new-drodown';


const initialFilterValues: MainEventsFilterFormValues = {
  event_name: undefined,
  start_date: undefined,
  end_date: undefined,
  event_date: undefined,
  sports_category_id: undefined,
  currency_id: undefined
};


export const EventsView = () => {

  const { t } = useTranslation();
  const permissions = usePermissions();
  const { user } = useUserContext()
  const { params, updateParams, resetPage } =
    usePaginationParams<MainEventsFilterFormPaginated>({
      page: 1,
      size: 10,
      order_by: 'id',
      ...initialFilterValues,
    });

  const { data, isFetching, refetch } = useGetEvents(params, { retry: false });

  const navigate = useNavigate()
  const categoryParams: any = { Flag: "category" };

  const currencyParams: any = { Flag: "Currency" };

  const { data: sportsData } = useGetNewDropdown(categoryParams, { retry: false });

  const { data: currencyData } = useGetNewDropdown(currencyParams, { retry: false });

  const shouldHandleFieldCreation = permissions?.fields.add;

  const handleFiltersChange = (values: MainEventsFilterFormValues) => {
    updateParams(values);
    resetPage();
    refetch()
  };


  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const formId = 'main-events-filters-form';

  const handleCloseModal = () => {
    setShowAddEventModal(false);
    document.body.style.overflow = 'visible';
  }

  const handleOpenModal = () => {
    setShowAddEventModal(true);
    document.body.style.overflow = 'hidden';
  }
  //console.log(user,"user");

  useEffect(() => {
    if (user?.user_id === 107093) {
      navigate('/transactions/participate-List')
      window.location.reload();
    }

  }, ["/transactions/participate-List"])
  //console.log(user?.user_id,"user?.user_id");

  // useEffect(() => {
  //   //if(user?.user_id===107093)
  //     {user?.user_id===107093 &&
  //     window.location.reload();}
  // }, []);

  return (
    <>
      <ContentTopBar
        title={t('events.heading')}
        // renderHeadingContent={
        //   <MainFilters onChange={handleMainFiltersChange} />
        // }
        renderButtons={
          <>
            {shouldHandleFieldCreation ? (
              <>
                <Button className='btn_common'
                  // icon={!screens.xs ? <PlusOutlined /> : undefined}
                  onClick={handleOpenModal}
                >
                  {t('events.buttons.addEvent')}
                </Button>
                <div className={`cstmrightmdl ${showAddEventModal && "show"}`}>
                  <AddEventModal onClose={handleCloseModal}
                    isOpen={showAddEventModal}
                    sportsData={sportsData}
                    currencyData={currencyData}
                  />
                </div>

              </>
            ) : null}
          </>
        }
      />

      <ContentFilters formId={formId}>
        <MainEventFilterForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
          sportsData={sportsData}
          currencyData={currencyData}
        />
      </ContentFilters>

      <EventsTable
        data={data}
        isLoading={isFetching}
        tableParams={params}
        updateParams={updateParams}
        refetch={refetch}
      />
      <Outlet />
    </>
  );
};
