import { checkPermission, useNewPermissions, useUserContext } from 'features/users';
import { useEffect, useState } from 'react';

import FeeCategory from '../components/gender/FeeCategory';
import Gender from '../components/gender/gender';
import Transaction from '../components/gender/Transaction';
import SalesView from '../components/monthly/salesView';
import NationalityView from '../components/Nationality/NationalityView';
import ParticipantView from '../components/participant-chart/Participant-graph';
import FeeType from '../components/Registered/Fee-Type';
import RegisteredUser from '../components/Registered/RegisteredUser';
import { CategoryEventTopBar } from '../components/top-bar/Category-event-top-bar';
import {
  categoryFilter,
  // dateFilterChange,
  //   genderCategoryFilter,
  //   getInitialGenderFilterValue,
  listDataType,
  participantData
} from '../components/type';
import { useGetEvents, useGetGenderData, useGetParticipantData } from '../use-cases/getDashboardData';


const DashboardView = () => {
  const { user } = useUserContext();
  const permissionsNew_ = useNewPermissions();
  const eventId = user?.user_id == 107093 ? 732 : 0;
  //Monthly Target Graph
  // const [monthlyTargetGraphData, setMonthlyTargetGraphData] = useState<participantData[]>([]);
  // const [monthlyTargetEId, SetMonthlyTargetEId] = useState<number>(0);
  // const [monthlyTargetCId, SetMonthlyTargetCId] = useState<number>(0);

  //Sales Graph
  const [salesGraphData, setSalesGraphData] = useState<participantData[]>([]);
  const [salesEId, SetSalesEId] = useState<number>(eventId);
  const [salesCId, SetSalesCId] = useState<number>(0);

  //Participant Graph  
  const [participantGraphData, setParticipantGraphData] = useState<participantData[]>([]);
  const [participantEId, SetParticipantEId] = useState<number>(eventId);
  const [participantCId, SetParticipantCId] = useState<number>(0);
  // Gender Graph
  const [genderGraphData, setGenderGraphData] = useState<participantData[]>([]);
  const [genderEId, SetGenderEId] = useState<number>(eventId);
  const [genderCId, SetGenderCId] = useState<number>(0);
  // Transaction Graph
  const [transactionGraphData, setTransactionGraphData] = useState<participantData[]>([]);
  const [transactionEId, SetTransactionEId] = useState<number>(eventId);
  const [transactionCId, SetTransactionCId] = useState<number>(0);
  // Fee Category Graph  
  const [feeCategoryGraphData, setFeeCategoryGraphData] = useState<participantData[]>([]);
  const [feeCategoryEId, SetFeeCategoryEId] = useState<number>(eventId);
  const [feeCategoryCId, SetFeeCategoryCId] = useState<number>(0);
  // Nationality Graph  
  const [nationalityGraphData, setNationalityGraphData] = useState<participantData[]>([]);
  const [nationalityEId, SetNationalityEId] = useState<number>(eventId);
  const [nationalityCId, SetNationalityCId] = useState<number>(0);
  // Fee Category Graph     
  const [feeTypeGraphData, setFeeTypeGraphData] = useState<participantData[]>([]);
  const [feeTypeEId, SetFeeTypeEId] = useState<number>(eventId);
  const [feeTypeCId, SetFeeTypeCId] = useState<number>(0);


  //const [genderFilterValue,SetGenderFilterValue] = useState<genderCategoryFilter>(getInitialGenderFilterValue) ;

  const { data: eventList } = useGetEvents(`Flag=${'Events'}`);
  // Monthly target 
  // const { data: monthlyTargetGData, error: fmonthlyTargetGraphError, isLoading: monthlyTargetIsLoading } = useGetParticipantData(monthlyTargetEId, monthlyTargetCId, 'MonthlyTarget');
  // Sales
  const { data: salesGData } = useGetParticipantData(salesEId, salesCId, 'Sales');

  const { data: sportCategory } = useGetEvents(`Flag=${'Category'}`);

  const { data: genderGData } = useGetGenderData(genderEId, genderCId, 'Gender');

  const { data: participantGData } = useGetParticipantData(participantEId, participantCId, 'Participants');

  const { data: transactionGData } = useGetParticipantData(transactionEId, transactionCId, 'Transaction');

  const { data: feeCategoryGData } = useGetParticipantData(feeCategoryEId, feeCategoryCId, 'FeeCategory');

  const { data: nationalityGData } = useGetParticipantData(nationalityEId, nationalityCId, 'Nationality');

  const { data: feeTypeGData } = useGetParticipantData(feeTypeEId, feeTypeCId, 'FeeType');


  const setGData = (values: listDataType, type: number) => {
    // const gData :participantData[] = [] ;
    // values.items.forEach((element)=>{          
    //   gData.push({name:element.name,value:element.value,percentage:element.percentage});        
    // })


    if (type == 1) {
      setParticipantGraphData(values.items);
    }
    else if (type == 2) {
      setGenderGraphData(values.items);
    } else if (type == 3) {
      setTransactionGraphData(values.items);
    } else if (type == 4) {
      setFeeCategoryGraphData(values.items);
    } else if (type == 5) {
      setFeeTypeGraphData(values.items);
    } else if (type == 6) {
      //monthly target 
      //setMonthlyTargetGraphData();

    } else if (type == 7) {
      //sales
      setSalesGraphData(values.items);
    } else if (type == 8) {
      // console.log('nationality_'+JSON.stringify(values.items))
      // nationality
      setNationalityGraphData(values.items);
    }

  }

  useEffect(() => {
    if (participantGData) {
      setGData(participantGData, 1);
    }

    if (genderGData) {
      setGData(genderGData, 2);
    }

    if (transactionGData) {
      setGData(transactionGData, 3);
    }

    if (feeCategoryGData) {
      setGData(feeCategoryGData, 4);
    }

    if (feeTypeGData) {
      setGData(feeTypeGData, 5);
    }

    // if (monthlyTargetGData) {
    //   setGData(monthlyTargetGData, 6);
    // }

    if (salesGData) {
      setGData(salesGData, 7);
    }

    if (nationalityGData) {
      setGData(nationalityGData, 8);
    }


  }, [salesGData, participantGData, genderGData, transactionGData, feeCategoryGData, feeTypeGData, nationalityGData])

  //monthlyTargetGData
  const handleFiltersChange = (values: categoryFilter, type: number) => {
    if (type == 1) {
      if (values.participant_event != undefined) {
        SetParticipantEId(values.participant_event);
      }
      if (values.participant_category != undefined) {
        SetParticipantCId(values.participant_category);
      }
    } else if (type == 2) {
      if (values.participant_event != undefined) {
        SetGenderEId(values.participant_event);
      }

      if (values.participant_category != undefined) {
        SetGenderCId(values.participant_category);
      }
    } else if (type == 3) {
      if (values.participant_event != undefined) {
        SetTransactionEId(values.participant_event);
      }

      if (values.participant_category != undefined) {
        SetTransactionCId(values.participant_category);
      }
    } else if (type == 4) {
      if (values.participant_event != undefined) {
        SetFeeCategoryEId(values.participant_event);
      }

      if (values.participant_category != undefined) {
        SetFeeCategoryCId(values.participant_category);
      }
    } else if (type == 5) {
      if (values.participant_event != undefined) {
        SetFeeTypeEId(values.participant_event);
      }

      if (values.participant_category != undefined) {
        SetFeeTypeCId(values.participant_category);
      }
    } else if (type == 6) {
      //monthly target 

    } else if (type == 7) {
      //sales        
      if (values.participant_event != undefined) {
        SetSalesEId(values.participant_event);
      }
      if (values.participant_category != undefined) {
        SetSalesCId(values.participant_category);
      }
    } else if (type == 8) {
      //Nationality
      if (values.participant_event != undefined) {
        SetNationalityEId(values.participant_event);
      }

      if (values.participant_category != undefined) {
        SetNationalityCId(values.participant_category);
      }
    }


  };


  return (
    <div >
      {checkPermission(permissionsNew_, "Dashboard") ? (<>
        <div className='dash_head'>
          <h3>DASHBOARD</h3>
        </div>

        {/* <div className='dash_graph dash_gph_head'>
      <h4>Monthly Target</h4>
       <MonthlyTopBar           
          handleFilter={handleFiltersChange}
          eventList={eventList}         
          graphType={1}         
        /> 
        <div className='dash_chart'>
        
        </div>
      </div> */}


        {/* <div className='col2_graph'>
        <div className='col2_head'>
          <h3>Category growth-Sales</h3>
        </div>
        <div className='dash_row'>
          <div className='dash_box'>
          <CategoryGrowthSalesTopBar />
            <div className='dash_chart'>
              <GrowthSalesFirst data={data} />
            </div>
          </div>
          <div className='dash_box'>
            <CategoryGrowthSalesTopBar />
            <div className='dash_chart'>
              <GrowthSalesSecond data={data} />
            </div>
          </div>
        </div>
      </div> */}

        {/* <div className='dash_graph'>
        <div className='dash_gph_head'>
          <h4>Sales Up to date comparison</h4>
          <CategoryEventTopBar />
        </div>
        <div className='dash_chart'>
          <SalesComparisonView data={data} />
        </div>
      </div> */}

        {/* <div className='col2_graph'>
        <div className='col2_head'>
          <h3>Category growth-Participants</h3>
        </div>
        <div className='dash_row'>
          <div className='dash_box'>
          <CategoryGrowthSalesTopBar />
            <div className='dash_chart'>
              <GrowthParticipantsFirst data={data} />
            </div>
          </div>
          <div className='dash_box'>
          <CategoryGrowthSalesTopBar />
            <div className='dash_chart'>
              <GrowthParticipantsSecond data={data} />
            </div>
          </div>
        </div>
      </div> */}
        {/* 
      <div className='dash_graph'>
        <div className='dash_gph_head'>
          <h4>Participant-up to date comparison</h4>
          <CategoryEventTopBar />
        </div>
        <div className='dash_chart'>
          <MonthlyView data={data} />
        </div>
      </div> */}

        <div className='dash_graph'>
          <div className='dash_gph_head'>
            <h4>Sales</h4>
            <CategoryEventTopBar
              handleFilter={handleFiltersChange}
              eventList={eventList}
              sportCategory={sportCategory}
              formId='Sales_Graph'
              graphType={7}
            />
          </div>
          <div className='dash_chart'>
            <SalesView data={salesGraphData} />
          </div>
        </div>

        <div className='dash_graph'>
          <div className='dash_gph_head'>
            <h4>Participant</h4>
            <CategoryEventTopBar
              handleFilter={handleFiltersChange}
              eventList={eventList}
              sportCategory={sportCategory}
              formId='Participant_Graph'
              graphType={1}
            />
          </div>
          <div className='dash_chart'>
            <ParticipantView data={participantGraphData} />
          </div>
        </div>

        <div className='col3_graph'>

          <div className='dash_row'>
            <div className='dash_box'>
              <div className='dash_gph_head'>
                <h4>Gender</h4>
                <CategoryEventTopBar
                  handleFilter={handleFiltersChange}
                  eventList={eventList}
                  sportCategory={sportCategory}
                  formId='Gender_Graph'
                  graphType={2}
                />
              </div>
              <div className='dash_chart'>
                <Gender genderGraphData={genderGraphData} />
              </div>
            </div>

            <div className='dash_box'>
              <div className='dash_gph_head'>
                <h4>Transaction</h4>
                <CategoryEventTopBar
                  handleFilter={handleFiltersChange}
                  eventList={eventList}
                  sportCategory={sportCategory}
                  formId='Transaction_Graph'
                  graphType={3}
                />
              </div>
              <div className='dash_chart'>
                <Transaction transactionGraphData={transactionGraphData} />
              </div>
            </div>


            <div className='dash_box'>
              <div className='dash_gph_head'>
                <h4>Fee Category</h4>
                <CategoryEventTopBar
                  handleFilter={handleFiltersChange}
                  eventList={eventList}
                  sportCategory={sportCategory}
                  formId='Fee_Category_Graph'
                  graphType={4}
                />
              </div>
              <div className='dash_chart'>
                <FeeCategory feeCategoryGraphData={feeCategoryGraphData} />

              </div>
            </div>

          </div>
        </div>

        <div className='dash_graph'>
          <div className='dash_gph_head'>
            <h4>Nationality</h4>
            <CategoryEventTopBar
              handleFilter={handleFiltersChange}
              eventList={eventList}
              sportCategory={sportCategory}
              formId='Nationality_Graph'
              graphType={8}
            />
          </div>
          <div className='dash_chart'>
            <NationalityView data={nationalityGraphData} />
          </div>
        </div>

        <div className='col2_graph'>

          <div className='dash_row'>
            <div className='dash_box'>
              <div className='dash_gph_head'>
                <h4>Fee Type</h4>
                <CategoryEventTopBar
                  handleFilter={handleFiltersChange}
                  eventList={eventList}
                  sportCategory={sportCategory}
                  formId='Fee_Type_Graph'
                  graphType={5}
                />
              </div>
              <div className='dash_chart'>
                <FeeType feeTypeGraphData={feeTypeGraphData} />
              </div>
            </div>
            <div className='dash_box'>
              <div className='dash_gph_head'>
                <h4>Registered User</h4>
                <CategoryEventTopBar
                  handleFilter={handleFiltersChange}
                  eventList={eventList}
                  sportCategory={sportCategory}
                  formId='Registered_Graph'
                  graphType={6}
                />
              </div>
              <div className='dash_chart'>
                <RegisteredUser />

              </div>
            </div>
          </div>
        </div>
      </>) : ""}


    </div>
  )
}

export default DashboardView;
