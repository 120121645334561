import { SendOutlined } from '@ant-design/icons';
import { Button, Empty, Skeleton, TablePaginationConfig, Tooltip } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
} from 'components/table/table.styled';
import { TableLoader } from 'components/table-loader/table-loader';
import { useGetSendEmail } from 'features/transaction_list/use-cases/get-send-email';
import { usePermissions, useUserContext } from 'features/users';
import { EndUserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from 'types';
type ParticipateTableProps = {
  isSocialListLoading: boolean;
  DeliveryList: any;
  refetch: any,
  tableParams: EndUserFilterFormValuesPaginated;
  updateParams: Dispatch<SetStateAction<EndUserFilterFormValuesPaginated>>;
};
export const ParticipantList = ({
  DeliveryList,
  isSocialListLoading,
  tableParams,
  updateParams,
}: ParticipateTableProps) => {
  const { user } = useUserContext();
  const userHasAccessToEventList: any = user?.user_id != 107093;
  const permissions = usePermissions();
  const navigate = useNavigate();

  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<User> | SorterResult<User>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
          ? 'name'

          : '-name',
    });
  };


  const { mutate } = useGetSendEmail();

  const handleNavigate = (record: any) => {
    navigate(`/transactions/detail/${record.id}/edit`); // Navigate with encoded name
  };


  const handleSendMail = (e: React.MouseEvent<HTMLElement>, sendId: string | number) => {
    e.stopPropagation(); // Prevent default event behavior
    const payload = {
      participant_id: sendId
    }
    mutate(payload)
  };

  if (!permissions) return null;

  return (
    <>
      <StyledTableHeader>
        <div style={{ marginTop: '32px', color: 'black' }}>
          <RecordCountBadge
            text={"Participante found"}
            count={isSocialListLoading ? 0 : DeliveryList?.total || 0}
          />
        </div>
      </StyledTableHeader>
      <Table
        dataSource={isSocialListLoading ? [] : DeliveryList?.items}
        className='cstltblwthldr cstm_table'
        // loading={isSocialListLoading}
        locale={{
          emptyText: isSocialListLoading ? <Skeleton active={true} /> : <Empty />
        }}
        loading={isSocialListLoading && { indicator: <div><TableLoader /></div> }}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams?.page,
          defaultPageSize: tableParams?.size,
          total: DeliveryList?.total,
          // showSizeChanger: false
        }}
        onRow={(record: any) => {
          return {
            onClick: () => {
              const selection = window?.getSelection()?.toString();
              if (!selection?.length) {
                handleNavigate(record)
              }
            },
          };
        }}
        scroll={{ x: 1440 }}
      >
        <Column
          title={'Invoice Id'}
          dataIndex={'invoice_id'}
        />
        <Column
          title={'Participant Id'}
          dataIndex={'participant_id'}
        // sorter={true}
        // sortDirections={['ascend', 'descend', 'ascend']}
        // defaultSortOrder={'ascend'}
        />
        <Column
          title={'First Name'}
          dataIndex={'first_name'}
        // sorter={true}
        // sortDirections={['ascend', 'descend', 'ascend']}
        // defaultSortOrder={'ascend'}
        />
        <Column
          title={'Last Name'}
          dataIndex={'last_name'}
        // sorter={true}
        // sortDirections={['ascend', 'descend', 'ascend']}
        // defaultSortOrder={'ascend'}
        />
        {userHasAccessToEventList && <Column
          title={'Email'}
          dataIndex={'email'}

        />}
        {userHasAccessToEventList &&
          <Column
            title={'Phone'}
            dataIndex={'phone'}

          />}
        {userHasAccessToEventList &&
          <Column
            title={'Event'}
            dataIndex={'event_name'}

          />}

        <Column
          title={'Transaction Date'}
          dataIndex={'transaction_date'}

        />
        <Column
          title={'Transaction Status'}
          dataIndex={'payment_status'}

        />

        <Column
          title="Action"
          render={(record: any) => {
            const isPaid = record?.payment_status === "Paid";
            const participantId = record?.id;

            return (
              isPaid && (
                <Tooltip title="Send Mail">
                  <Button
                    // loading={isLoading}
                    icon={<SendOutlined style={{ color: 'green' }} />}
                    onClick={(e) => handleSendMail(e, participantId)}
                  />
                </Tooltip>
              )
            );
          }}
        />
      </Table>
    </>
  );
};
