import { checkPermission } from 'features/users';
import i18n from 'locales/i18n';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

import cms from './../../assets/icons/cms.svg'
import dashboard_icon from './../../assets/icons/dashboard .svg'
import Eventm from './../../assets/icons/event.svg'
// import logs from './../../assets/icons/logs.svg'
import notify from './../../assets/icons/notification .svg'
import shop from './../../assets/icons/shop_item.svg'
import transaction from './../../assets/icons/transaction.svg'
import user from './../../assets/icons/user.svg'
import vending from './../../assets/icons/vending-machine.svg'
import { ItemWithEnforcedKey } from './menu-items.types';
import { sideBarPermission } from './side-bar-permission';

export const getMenuItems = (

  permissions: any


): ItemWithEnforcedKey[] => [

    checkPermission(permissions, sideBarPermission.dashboard) ?
      {
        key: ROUTES.DASHBOARD,
        icon: <img src={dashboard_icon} alt="" />,
        label: (
          <NavLink to={ROUTES.DASHBOARD}>
            Dashboard
          </NavLink>
        ),
      } : null
    ,


    checkPermission(permissions, sideBarPermission.eventList) ?
      {
        key: ROUTES.EVENTS_ALL,
        label: i18n.t('sideBar.menu.eventManagement.all'),
        icon: <img src={Eventm} alt="" />,
        children: [
          {
            key: ROUTES.EVENTS,
            label: (
              <NavLink to={ROUTES.EVENTS}>
                {i18n.t('sideBar.menu.eventManagement.eventList')}
              </NavLink>
            ),
          },

          // {
          //   key: ROUTES.EVENTS_REQUESTED_EVENT,
          //   label: (
          //     <NavLink to={ROUTES.EVENTS_REQUESTED_EVENT}>
          //       Requested Events
          //     </NavLink>
          //   ),
          // },

          // {
          //   key: ROUTES.EVENTS_DYNAMIC_LAYOUT,
          //   label: (
          //     <NavLink to={ROUTES.EVENTS_DYNAMIC_LAYOUT}>
          //       Dynamic Layout
          //     </NavLink>
          //   ),
          // },
        ],
      } : null
    ,
    (checkPermission(permissions, sideBarPermission.invoiceList) || checkPermission(permissions, sideBarPermission.participantList) || checkPermission(permissions, sideBarPermission.walletTransaction) || checkPermission(permissions, sideBarPermission.bulkUpload) || checkPermission(permissions, sideBarPermission.itemSales) || checkPermission(permissions, sideBarPermission.reports)) ?
      {
        key: ROUTES.TRANSACTION,
        label: "Transactions",
        icon: <img src={transaction} alt="" />,
        children: [
          checkPermission(permissions, sideBarPermission.invoiceList) ?
            {
              key: ROUTES.TRANSACTION_INVOICE_LIST,
              label: (
                <NavLink to={ROUTES.TRANSACTION_INVOICE_LIST}>
                  Invoice List
                </NavLink>
              ),
            } : null,
          checkPermission(permissions, sideBarPermission.participantList) ? {
            key: ROUTES.TRANSACTION__PARTICIPATE_LIST,
            label: (
              <NavLink to={ROUTES.TRANSACTION__PARTICIPATE_LIST}>
                Participant list
              </NavLink>
            ),
          } : null,


          checkPermission(permissions, sideBarPermission.waitingList) ? {
            key: ROUTES.TRANSACTION__WAITING_LIST,
            label: (
              <NavLink to={ROUTES.TRANSACTION__WAITING_LIST}>
                Waiting List
              </NavLink>
            ),
          } : null,

          checkPermission(permissions, sideBarPermission.walletTransaction) ? {
            key: ROUTES.TRANSACTION_WALLET_LIST,
            label: (
              <NavLink to={ROUTES.TRANSACTION_WALLET_LIST}>
                Wallet Transactions
              </NavLink>
            ),
          } : null,

          checkPermission(permissions, sideBarPermission.bulkUpload) ? {
            key: ROUTES.TRANSACTION__BULK_UPLOAD_LIST,
            label: (
              <NavLink to={ROUTES.TRANSACTION__BULK_UPLOAD_LIST}>
                Bulk Upload
              </NavLink>
            ),
          } : null,

          // checkPermission(permissions, sideBarPermission.bulkUpload) ? 
          {
            key: ROUTES.TRANSACTION__GROUP_REGISTRATION_LIST,
            label: (
              <NavLink to={ROUTES.TRANSACTION__GROUP_REGISTRATION_LIST}>
                Group Registration
              </NavLink>
            ),
          },
          // : null,


          checkPermission(permissions, sideBarPermission.itemSales) ? {
            key: ROUTES.TRANSACTION__ITEM_SALES,
            label: (
              <NavLink to={ROUTES.TRANSACTION__ITEM_SALES}>
                Item Sales
              </NavLink>
            ),
          } : null,

          checkPermission(permissions, sideBarPermission.reports) ? {
            key: ROUTES.TRANSACTION__REPORTS,
            label: (
              <NavLink to={ROUTES.TRANSACTION__REPORTS}>
                Reports
              </NavLink>
            ),
          } : null,



          checkPermission(permissions, sideBarPermission.participantList) ? {
            key: ROUTES.TRANSACTION__EXPO_BOOTH_LIST,
            label: (
              <NavLink to={ROUTES.TRANSACTION__EXPO_BOOTH_LIST}>
                Expo Booth
              </NavLink>
            ),
          } : null,


        ],
      } : null
    ,

    (checkPermission(permissions, sideBarPermission.discoveryBanner) || checkPermission(permissions, sideBarPermission.sportsCategory) || checkPermission(permissions, sideBarPermission.currency) || checkPermission(permissions, sideBarPermission.managePartners) || checkPermission(permissions, sideBarPermission.globalDisclaimer) || checkPermission(permissions, sideBarPermission.manageCountries) || checkPermission(permissions, sideBarPermission.manageArea) || checkPermission(permissions, sideBarPermission.manageTShirtSize) || checkPermission(permissions, sideBarPermission.eventMaster) || checkPermission(permissions, sideBarPermission.raceKit)) ?
      {
        key: ROUTES.CMS,
        label: "CMS",
        icon: <img src={cms} alt="" />,
        children: [
          checkPermission(permissions, sideBarPermission.discoveryBanner) ? {
            key: ROUTES.CMS_DISCOVER_BANNER,
            label: (
              <NavLink to={ROUTES.CMS_DISCOVER_BANNER}>
                Discover Banner
              </NavLink>
            ),
          } : null,
          checkPermission(permissions, sideBarPermission.sportsCategory) ? {
            key: ROUTES.CMS_SPORTS_CATEGORY,
            label: (
              <NavLink to={ROUTES.CMS_SPORTS_CATEGORY}>
                Sports Category
              </NavLink>
            ),
          } : null,

          checkPermission(permissions, sideBarPermission.currency) ? {
            key: ROUTES.CMS_CURRENCY,
            label: (
              <NavLink to={ROUTES.CMS_CURRENCY}>
                Currency
              </NavLink>
            ),
          } : null,

          checkPermission(permissions, sideBarPermission.manageCountries) ? {
            key: ROUTES.CMS_CURRENCY_CONVERSION,
            label: (
              <NavLink to={ROUTES.CMS_CURRENCY_CONVERSION}>
                Currency Conversion
              </NavLink>
            ),
          } : null,

          checkPermission(permissions, sideBarPermission.managePartners) ? {
            key: ROUTES.CMS_MANAGE_PARTNERS,
            label: (
              <NavLink to={ROUTES.CMS_MANAGE_PARTNERS}>
                Manage Partners
              </NavLink>
            ),
          } : null,


          checkPermission(permissions, sideBarPermission.globalDisclaimer) ? {
            key: ROUTES.CMS_GLOBAL_DISCLAIMER,
            label: (
              <NavLink to={ROUTES.CMS_GLOBAL_DISCLAIMER}>
                Global Disclaimer
              </NavLink>
            ),
          } : null,

          checkPermission(permissions, sideBarPermission.manageCountries) ? {
            key: ROUTES.CMS_MANAGE_COUNTRIES,
            label: (
              <NavLink to={ROUTES.CMS_MANAGE_COUNTRIES}>
                Manage Countries
              </NavLink>
            ),
          } : null,



          checkPermission(permissions, sideBarPermission.manageArea) ? {
            key: ROUTES.CMS_MANAGE_AREA,
            label: (
              <NavLink to={ROUTES.CMS_MANAGE_AREA}>
                Manage Area
              </NavLink>
            ),
          } : null,
          checkPermission(permissions, sideBarPermission.manageTShirtSize) ? {
            key: ROUTES.CMS_MANAGE_T_SHIRT_SIZE,
            label: (
              <NavLink to={ROUTES.CMS_MANAGE_T_SHIRT_SIZE}>
                Manage T-shirt Size
              </NavLink>
            ),
          } : null,
          checkPermission(permissions, sideBarPermission.eventMaster) ? {
            key: ROUTES.CMS_EVENT_MASTER,
            label: (
              <NavLink to={ROUTES.CMS_EVENT_MASTER}>
                Event Master
              </NavLink>
            ),
          } : null,
          checkPermission(permissions, sideBarPermission.raceKit) ? {
            key: ROUTES.CMS_RACE_KIT,
            label: (
              <NavLink to={ROUTES.CMS_RACE_KIT}>
                Race Kit
              </NavLink>
            ),
          } : null,
        ],
      } : null
    ,
    (checkPermission(permissions, sideBarPermission.couponManagement) || checkPermission(permissions, sideBarPermission.category) || checkPermission(permissions, sideBarPermission.itemList) || checkPermission(permissions, sideBarPermission.deliveryCharge) || checkPermission(permissions, sideBarPermission.colorManagement) || checkPermission(permissions, sideBarPermission.sizeManagement)) ?
      {
        key: ROUTES.SHOP_ITEM,
        label: i18n.t('sideBar.menu.shops.all'),
        icon: <img src={shop} alt="" />,
        children: [
          checkPermission(permissions, sideBarPermission.couponManagement) ? {
            key: ROUTES.COUPON_ITEM,
            label: (
              <NavLink to={ROUTES.COUPON_ITEM}>
                {'Coupon Management'}
              </NavLink>
            ),
          } : null,
          checkPermission(permissions, sideBarPermission.category) ? {
            key: ROUTES.SHOP_ITEM_CATEGORY,
            label: (
              <NavLink to={ROUTES.SHOP_ITEM_CATEGORY}>
                {i18n.t('sideBar.menu.shops.category')}
              </NavLink>
            ),
          } : null,
          checkPermission(permissions, sideBarPermission.itemList) ? {
            key: ROUTES.SHOP_ITEM_ITEM_LIST,
            label: (
              <NavLink to={ROUTES.SHOP_ITEM_ITEM_LIST}>
                {i18n.t('sideBar.menu.shops.itemlist')}
              </NavLink>
            ),
          } : null,
          checkPermission(permissions, sideBarPermission.deliveryCharge) ? {
            key: ROUTES.SHOP_ITEM_DELIVERY_CHARGES,
            label: (
              <NavLink to={ROUTES.SHOP_ITEM_DELIVERY_CHARGES}>
                {i18n.t('sideBar.menu.shops.Deliverycharge')}
              </NavLink>
            ),
          } : null,
          checkPermission(permissions, sideBarPermission.colorManagement) ? {
            key: ROUTES.SHOP_ITEM_COLOR_MANAGEMENT,
            label: (
              <NavLink to={ROUTES.SHOP_ITEM_COLOR_MANAGEMENT}>
                {i18n.t('sideBar.menu.shops.Colormanagement')}
              </NavLink>
            ),
          } : null,
          checkPermission(permissions, sideBarPermission.sizeManagement) ? {
            key: ROUTES.SHOP_ITEM_SIZE,
            label: (
              <NavLink to={ROUTES.SHOP_ITEM_SIZE}>
                {i18n.t('sideBar.menu.shops.sizemanagement')}
              </NavLink>
            ),
          } : null,
          // {
          //   key: ROUTES.SHOP_ITEM_FAQ,
          //   label: (
          //     <NavLink to={ROUTES.SHOP_ITEM_FAQ}>
          //       {i18n.t('sideBar.menu.shops.faq')}
          //     </NavLink>
          //   ),
          // },
        ],
      } : null
    ,
    (checkPermission(permissions, sideBarPermission.admins) || checkPermission(permissions, sideBarPermission.customerList) || checkPermission(permissions, sideBarPermission.agent) || checkPermission(permissions, sideBarPermission.volunteer)) ?
      {
        key: ROUTES.USERS,
        label: "User Management",
        icon: <img src={user} alt="" />,
        children: [
          checkPermission(permissions, sideBarPermission.admins) ? {
            key: ROUTES.USERS_ADMIN,
            label: (
              <NavLink to={ROUTES.USERS_ADMIN}>
                Admins
              </NavLink>
            ),
          } : null,
          checkPermission(permissions, sideBarPermission.customerList) ? {
            key: ROUTES.USERS_APP,
            label: (
              <NavLink to={ROUTES.USERS_APP}>
                Customer List
              </NavLink>
            ),
          } : null,
          checkPermission(permissions, sideBarPermission.agent) ? {
            key: ROUTES.USERS_AGENT,
            label: (
              <NavLink to={ROUTES.USERS_AGENT}>
                Agent
              </NavLink>
            ),
          } : null,
          checkPermission(permissions, sideBarPermission.volunteer) ? {
            key: ROUTES.VOLUNTEER_LIST,
            label: (
              <NavLink to={ROUTES.VOLUNTEER_LIST}>
                Volunteer
              </NavLink>
            ),
          } : null,
          {
            key: ROUTES.USER_MANAGEMENT_LOGS,
            label: (
              <NavLink to={ROUTES.USER_MANAGEMENT_LOGS}>
                User Logs
              </NavLink>
            ),
          },
        ],
      } : null
    ,
    // checkPermission(permissions, sideBarPermission.activityLog) ?
    //   {
    //     key: ROUTES.LOGS,
    //     label: "Logs",
    //     icon: <img src={logs} alt="" />,
    //     children: [
    //       {
    //         key: ROUTES.LOGS_ACTIVITY_LOG,
    //         label: (
    //           <NavLink to={ROUTES.LOGS_ACTIVITY_LOG}>
    //             Activity Log
    //           </NavLink>
    //         ),
    //       },
    //     ]
    //   } : null
    // ,
    checkPermission(permissions, sideBarPermission.notification) ?
      {
        key: ROUTES.NOTIFICATION,
        label: "Notifications",
        icon: <img src={notify} alt="" />,
        children: [
          {
            key: ROUTES.NOTIFICATION_LIST,
            label: (
              <NavLink to={ROUTES.NOTIFICATION_LIST}>
                List
              </NavLink>
            ),
          },
          {
            key: ROUTES.NOTIFICATION_TYPE,
            label: (
              <NavLink to={ROUTES.NOTIFICATION_TYPE}>
                Type
              </NavLink>
            ),
          },
        ],
      } : null,
    (checkPermission(permissions, sideBarPermission.vendingMachine) || checkPermission(permissions, sideBarPermission.vendingMachineReports)) ?
      {
        key: ROUTES.VENDING_MACHINE,
        label: "Vending Machine",
        icon: <img src={vending} alt="" />,
        children: [
          checkPermission(permissions, sideBarPermission.vendingMachineReports) ? {
            key: ROUTES.VENDING_MACHINE_REPORT,
            label: (
              <NavLink to={ROUTES.VENDING_MACHINE_REPORT}>
                Vending Machine Report
              </NavLink>
            ),
          } : null,
          checkPermission(permissions, sideBarPermission.vendingMachine) ? {
            key: ROUTES.VENDING_MACHINE_EVENT,
            label: (
              <NavLink to={ROUTES.VENDING_MACHINE_EVENT}>
                Vending Machine Event
              </NavLink>
            ),
          } : null,
        ],
      } : null,

  ];
