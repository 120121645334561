
import { useUserContext } from 'features/users';
import { usePaginationParams } from 'hooks';
import { Outlet } from 'react-router-dom';

import { ParticipateListTopBar } from '../component/top-bar/participante-bar';
import { ParticiateFilterFormValues } from '../component/transaction-filter-form/type';
import { ParticipantList } from '../component/transaction-table-list/participante-list';
import { useGetParticipate } from '../use-cases/get-participate';
const getInitialFilterValues: any = {
  first_name: undefined,
  last_name: undefined,
  email: undefined,
  phone: undefined,
  event_name: undefined,
  invoice_id: undefined,
  invoice_no: undefined
};

export const ParticipateListView = () => {
  const { user } = useUserContext();
  const userHasAccessToEventList:any = user?.user_id != 107093;
  if(!userHasAccessToEventList){
  getInitialFilterValues.event_name="Riyadh Marathon 2025";
  }
  const { params, updateParams, resetPage } = usePaginationParams<any>({
    page: 1,
    size: 10,
    ...getInitialFilterValues,
  });
  const { data, refetch, isFetching } = useGetParticipate(params, { retry: false });
  const handleFiltersChange = (values: ParticiateFilterFormValues) => {
    updateParams(values);
    resetPage();
    refetch();
  };

  return (

    <>
      <ParticipateListTopBar
        handleFilter={handleFiltersChange}
        getInitialFilterValues={getInitialFilterValues}
      // refetch={""}
      />
      <ParticipantList
        isSocialListLoading={isFetching}
        DeliveryList={data}
        tableParams={params as any}
        updateParams={updateParams}
        refetch={refetch}

      />
      <Outlet />
    </>
  );
};
