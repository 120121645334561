import { sideBarPermission } from 'components/side-bar/side-bar-permission';
import { getUserPermissions, useUserContext } from 'features/users';

export const usePermissions = () => {
  const { user } = useUserContext();

  if (!user) return null;

  const permissions = getUserPermissions(user);

  return permissions;
};

export const useNewPermissions = () => {
  const { user } = useUserContext();
  if (!user) return null;

  const permissions = { user_role: user.role, role: user.Menus }; //getUserPermissions(user);

  return permissions;
};

type userRole = {
  id: string,
  menu: string,
  isPermission: boolean
}


export const checkPermission = (userPermission: any, menu_title: string) => {

  if (userPermission.user_role === "SuperAdmin") {
    return true;
  } else if (userPermission.user_role === "SuffixAdmin") {
    const getPermission = userPermission.role.filter((value: userRole) => value.menu == menu_title && value.isPermission == true);
    if (getPermission.length > 0) {
      return true;
    } else {
      return false;
    }
  } else if (userPermission.user_role === "SuffixAgent" && (menu_title == sideBarPermission.dashboard || menu_title == sideBarPermission.participantList || menu_title == sideBarPermission.eventList)) {
    return true;
  }
  else {
    return false;
  }

}

