export enum sideBarPermission {
    dashboard = 'Dashboard',
    eventList = 'Event List',
    invoiceList = 'Invoice List',
    participantList = 'Participant List',
    waitingList = 'Waiting List',
    itemSales = 'Item Sales',
    reports = 'Reports',
    walletTransaction = 'Wallet Transaction',
    bulkUpload = 'Bulk Upload',
    discoveryBanner = 'Discovery Banner',
    sportsCategory = 'Sports Category',
    currency = 'Currency',
    managePartners = 'Manage Partners',
    globalDisclaimer = 'Global Disclaimer',
    manageCountries = 'Manage Countries',
    manageArea = 'Manage Area',
    manageTShirtSize = 'Manage TShirt-Size',
    eventMaster = 'Event Master',
    raceKit = 'Race Kit',
    couponManagement = 'Coupon Management',
    category = 'Category',
    itemList = 'Item List',
    deliveryCharge = 'Delivery Charge',
    colorManagement = 'Color Management',
    sizeManagement = 'Size Management',
    admins = 'Admins',
    customerList = 'Customer List',
    agent = 'Agent',
    volunteer = "Volunteer",
    activityLog = 'Activity Log',
    notification = 'Notification',
    vendingMachineReports = 'Vending Machine Reports',
    vendingMachine = 'Vending Machine'
  }