import { FileExcelOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ContentTopBar } from 'components';
import { useState } from 'react';

import { AddUploadModal } from './add-bulk-upload-modal/add-upload-modal';


export const UploadListTopBar = ({ updatedTitle }: any) => {
  const [uploadModal, setUploadModal] = useState<boolean>(false);

  const handleOpenModal = () => {
    setUploadModal(true);
    document.body.style.overflow = 'hidden';
  }

  const handleCloseModal = () => {
    setUploadModal(false);
    document.body.style.overflow = 'visible';
  }

  return (
    <>
      <div className={`cstmrightmdl ${uploadModal && "show"}`}>
        <AddUploadModal
          onClose={handleCloseModal}
          isOpen={uploadModal}
        />
      </div>

      <ContentTopBar title={updatedTitle}
        renderButtons={
          // <Tooltip title={"Export"}>
          <Button
            icon={<FileExcelOutlined />}
            onClick={handleOpenModal}
            type='primary'
          // loading={loading}
          >
            Import Sheet
          </Button>
          // </Tooltip>

        }
      />

    </>

  );
};


