import { Button, Form, Grid, Input, Select } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useUserContext } from 'features/users';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ParticiateFilterFormField, ParticiateFilterFormValues } from './type';
const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
  formId: string;
  handleFilter: (values: ParticiateFilterFormValues) => void;
  initialValues: ParticiateFilterFormValues;
  closeModal?: () => void;
};

export const ParticipateFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersFilterFormProps) => {
  const { user } = useUserContext();
  const userHasAccessToEventList: any = user?.user_id != 107093;
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<ParticiateFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
    handleFilter({ ...initialValues })
  };

  const onFinish = (formValues: ParticiateFilterFormValues) => {
    if (!userHasAccessToEventList) {
      formValues[ParticiateFilterFormField.EVENTNAME] = "Riyadh Marathon 2025";
    }
    handleFilter(formValues);
    closeModal?.();
  };

  return (

    <Form<ParticiateFilterFormValues>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper>
        <StandardItemsWrapper>
          <div className='invoice_list'>
            <Form.Item
              // label={t('shops.formfield.fieldName.label')}
              label={'FIRST NAME'}
              name={ParticiateFilterFormField.FIRSTNAME}
            >
              <Input placeholder={'Enter First Name'} />
            </Form.Item>
            <Form.Item
              // label={t('shops.formfield.fieldName.label')}
              label={'LAST NAME'}
              name={ParticiateFilterFormField.LASTNAME}
            >
              <Input placeholder={'Enter last name'} />
            </Form.Item>
            {userHasAccessToEventList && <Form.Item
              // label={t('shops.formfield.fieldName.label')}
              label={'EMAIL'}
              name={ParticiateFilterFormField.EMAIL}
            >
              <Input placeholder={'Enter Email22'} />
            </Form.Item>}
            <Form.Item
              // label={t('shops.formfield.fieldName.label')}
              label={'INVOICE ID'}
              name={"invoice_id"}
            >
              <Input placeholder={'Enter Invoice Id'} />
            </Form.Item>
            <Form.Item
              // label={t('shops.formfield.fieldName.label')}
              label={'Participant Id'}
              name={ParticiateFilterFormField.PARTICIPANT}
            >
              <Input placeholder={'Enter Participant Id'} />
            </Form.Item>
            {userHasAccessToEventList && <Form.Item
              // label={t('shops.formfield.fieldName.label')}
              label={'Phone'}
              name={ParticiateFilterFormField.PHONE}
            >
              <Input placeholder={'Enter Phone No'} />
            </Form.Item>}
            {userHasAccessToEventList && <Form.Item
              // label={t('shops.formfield.fieldName.label')}
              label={'Event'}
              name={ParticiateFilterFormField.EVENTNAME}
            >
              <Input placeholder={'Enter Event'} />
            </Form.Item>}

            {userHasAccessToEventList && <Form.Item
              label={'Payment Status'}
              name={"transaction_status"}
            >
              <Select
                placeholder="Select Transaction Status"

                options={[
                  { value: 'PAID', label: 'PAID' },
                  { value: 'UNPAID', label: 'UNPAID' },
                ]}
              />
            </Form.Item>}

            {screens.lg && (
              <ContentFilters.ButtonsWrapper className='filter_btn'>
                <Button type='primary' htmlType='submit'>
                  {t('common.buttons.filter', { count: 1 })}
                </Button>
                <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
              </ContentFilters.ButtonsWrapper>
            )}
          </div>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

    </Form>
  );
};
